<template>
    <!-- Create a template that creates a slide with a Header and Body -->
    <div class="slide">
        <div class="header">
            <slot name="header">Well what is perseverance?</slot>
        </div>  
        <div class="body">
            <slot name="body">
                Well, according to the dictionary: 
                <br> Persistence in doing something despite difficulty or delay in achieving success.<br>

                An example of this is when you get home from school, and your tired and want to watch TV. But you know for Math
                you must finish homework, so before you watch TV you can persevere through the homework.<br>
            </slot>
        </div>
        <router-link to="/presentation/slide-two">
            <button class="button is-large is-dark mt-5 button-font" @click="next"><b class="font">Next Slide</b></button>
        </router-link>
    </div>

</template>

<style lang="scss" scoped>

    .font{
        font-family: inter;
    }
    .slide{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        border: 0;

        .header{
            font-size: 50pt;
            color: black;
            font-weight: bold;
            font-family: inter;
            margin-top: 15%;
            margin-bottom: 30pt;
        }

        .body{
            font-size: 22.5pt;
            color: gray;
            font-family: inter;
            padding-left: 10%;
            padding-right: 10%;
        }
    }
</style>
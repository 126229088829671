<template>
    <!-- Create a template that creates a slide with a Header and Body -->
    <div class="slide">
        <div class="header">
            <slot name="header">Conclusion</slot>
        </div>  
        <div class="body">
            <slot name="body">
                I think throughout our time today, we have seen how perseverance is something that is important. Thank you so much for having me! 🎉
            </slot>
        </div>

    </div>

</template>

<style lang="scss" scoped>

    .font{
        font-family: inter;
    }
    .slide{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        border: 0;

        .header{
            font-size: 50pt;
            color: black;
            font-weight: bold;
            font-family: inter;
            margin-top: 15%;
            margin-bottom: 30pt;
        }

        .body{
            font-size: 22.5pt;
            color: gray;
            font-family: inter;
            padding-left: 10%;
            padding-right: 10%;
        }
    }
</style>
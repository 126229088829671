<template>
    <!-- Create a template that creates a slide with a Header and Body -->
    <div class="slide">
        <div class="header">
            <slot name="header">What is one time that you kept trying and persevered through an issue?</slot>
        </div> <br>
        <textarea class="textarea textareaStyle" placeholder="When did you have to persevere..." v-model="response.value"></textarea>
        
        <button class="button is-link is-large is-dark mt-5 button-font" @click="next"><b class="font" @click="submit">Submit Anwser</b></button><br>
        <router-link to="/presentation/slide-five">
            <button class="button is-large is-dark mt-1 button-font" @click="next"><b class="font" >Next Slide</b></button>
        </router-link>
    </div>

</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import firebase from '../fbase.js';
import { useStore } from 'vuex';
import { reactive } from 'vue';

    export default {
        name: 'PresentationSlideTwo',
        setup() {

            const store = useStore();

            // create reactive variables to keep track of the input
            const response = reactive({
                value: ''
            });


            // get name from store
            const name = store.state.name;
            console.log("sld 2: ", name);

            const submit = () => {
                firebase.firestore().collection("slide-4").doc(name).set({
                    name: name,
                    response: response.value
                })
                .then(() => {
                    console.log("Document successfully written!");
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                });

                alert(
                    "Your response has been sent!"
                )
                response.value = '';
            }

            return {
                response,
                submit
            }
        }
    }

</script>

<style lang="scss" scoped>
    .textareaStyle{

        // space it out with padding
        width: 400pt;
    }
    .font{
        font-family: inter;
    }
    .slide{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        border: 0;

        .header{
            font-size: 40pt;
            color: black;
            font-weight: bold;
            font-family: inter;
            margin-top: 15%;
            margin-bottom: 30pt;
        }

        .body{
            font-size: 22.5pt;
            color: gray;
            font-family: inter;
            padding-left: 10%;
            padding-right: 10%;
        }
    }
</style>
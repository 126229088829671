import firebase from 'firebase';

const settings = {timestampsInSnapshots: true};

const config = {
    apiKey: "AIzaSyBA9Xkr7630ZhzgRNQhoKtxold2FAkuuho",
  authDomain: "perseverance-dbece.firebaseapp.com",
  projectId: "perseverance-dbece",
  storageBucket: "perseverance-dbece.appspot.com",
  messagingSenderId: "125535330767",
  appId: "1:125535330767:web:062d1a2f2f96a44047f2ba"
};
firebase.initializeApp(config);

firebase.firestore().settings(settings);

export default firebase;

import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { reactive } from 'vue'

  export default {
    name: "Home",
    setup() {
        const store = useStore()
        const router = useRouter()

        // create a name value from the input to keep reactive
        const name = reactive({
            value: ''
        })


        const login = () => {
            if(name.value == ''){
                alert('You must submit a name to continue.')
            }else{
                store.commit('setName', name.value)
                console.log("name in VueX store: ", name.value)

                //push to first slide
                router.push('/presentation/slide-one')
            }
        }

        return {
            config: {
                "developmentMode": false,
                "loginPermsWithoutDevMode": false,
                "domainRules": {
                    "lockToDomain": true,
                    "domain": "w-e-s.org",
                    "exception": "layladanielian@gmail.com"
                },
                "backendURL": "https://whichfoodisbetter.herokuapp.com"
            },
            name,
            login
        }
    }
  }

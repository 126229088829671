import { createStore } from 'vuex'

export default createStore({
  state: {
    name: "Random Person"
  },
  mutations: {
    setName(state, name) {
      state.name = name
    }
  },
  actions: {
  },
  getters: {
    getName(state) {
      return state.name
    }
  },
  modules: {
  }
})

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'

import PresentationSlideOne from '@/views/PresentationSlideOne.vue'
import PresentationSlideTwo from '@/views/PresentationSlideTwo.vue'
import PresentationSlideThree from '@/views/PresentationSlideThree.vue'
import PresentationSlideFour from '@/views/PresentationSlideFour.vue'
import PresentationSlideFive from '@/views/PresentationSlideFive.vue'
import PresentationSlideSix from '@/views/PresentationSlideSix.vue'
import PresentationSlideSeven from '@/views/PresentationSlideSeven.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/presentation/slide-one',
    name: 'PresentationSlideOne',
    component: PresentationSlideOne
  },
  {
    path: '/presentation/slide-two',
    name: 'PresentationSlideTwo',
    component: PresentationSlideTwo
  },
  {
    path: '/presentation/slide-three',
    name: 'PresentationSlideThree',
    component: PresentationSlideThree
  },
  {
    path: '/presentation/slide-four',
    name: 'PresentationSlideFour',
    component: PresentationSlideFour
  },
  {
    path: '/presentation/slide-five',
    name: 'PresentationSlideFive',
    component: PresentationSlideFive
  },
  {
    path: '/presentation/slide-six',
    name: 'PresentationSlideSix',
    component: PresentationSlideSix
  },
  {
    path: '/presentation/slide-seven',
    name: 'PresentationSlideSeven',
    component: PresentationSlideSeven
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
